import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../../components/seo';
import Navigation from '../../components/navigation';

import { Languages } from '../../tools/languages';
import Discover from '../../components/discover';
import ReactMarkdown from 'react-markdown';
import { Column, ColumnSection } from '../../components/column-section';

const DutchTermsAndConditionsPage = ({ location, data, language = Languages.NL }) => {
  const pathName = location.pathname;

  return (
    <>
      <SEO
        pathName={pathName}
        title="Algemene voorwaarden"
        description="De algemene voorwaarden van Bavak Security Group"
        lang={language}
      />
      <Navigation />
      <main>
        <h1>Algemene voorwaarden</h1>
        <ColumnSection>
          <Column>
            <ReactMarkdown>{data.page.content}</ReactMarkdown>
          </Column>
        </ColumnSection>
        <Discover language={language} dark={true} />
      </main>
    </>
  );
};

export const query = graphql`
  {
    page: strapiTermsAndConditions {
      content: content_nl
    }
  }
`;

export default DutchTermsAndConditionsPage;
